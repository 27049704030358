import React from 'react';

function App() {
  return (
    <div className="landing">
      <img src={require("./logo.svg")}/>
    </div>
  );
}

export default App;
